<script src="../../store/modules/iam.store.js"></script>
<template>
  <div>
    <v-row>
      <v-col>
        <FlyoutMenuHead :selectedItem="selectedItem" route="devices"/>
        <v-divider/>
        <CustomForm
        :formData="formData" 
        @onAddTag="addNewTag"
        @onSubmit="addDevice" 
        @onClose="() => { this.$router.go(-1) }">
        </CustomForm>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import FlyoutMenuHead from "../FlyoutMenuHead";
import CustomForm from "../CustomForm";

export default {
  name: "AddRunner",
  metaInfo: {
    title: "Add Runner",
    meta: [
      {
        name: "description",
        content: "Add a new runner to your space"
      }
    ]
  },
  components: {
    CustomForm,
    FlyoutMenuHead,
  },
  computed: {
    ...mapGetters('devices', ['getSelectedDevice']),
  },
  methods: {
    ...mapActions('devices', ['addRunnerDevice']),
    ...mapActions({"addTag": "tags/addTag"}),
    addNewTag(tag) {
      this.addTag(tag).then(response => {
        console.log(response);
      }).catch(error => {
        console.log(error);
      });
    },
    addDevice(form) {
      this.addRunnerDevice(form).then((response) => {
        this.$router.replace(`/devices/device/view/${response.space_uuid}/${response.uuid}/general`);
      });
    }
  },
  data: () => ({
    selectedItem: {
      name: "Add Runner Device",
      subtitle: "Once added, you will be able to connect and manage runner device.",
    },
    formData: {
      sections: [{
        title: 'Add New',
        inputs: {
          name: {
            value: "name",
            gridSize: 8,
            type: "text",
            placeholder: "Friendly name for your device",
            label: "Device Name"
          },
          device_class: {
            value: "device_class",
            gridSize: 8,
            type: "select",
            placeholder: "Enter device class property",
            label: "Device class",
            options: {
              items: [
                {name: "Kiosk", value: "kiosk"}, 
                {name: "Retail Pos", value: "retail_pos"}, 
                {name: "Visualization", value: "visualization"}],
              text: "name",
              value: "value"
            }
          },
          location_uuid: {
            value: "location_uuid",
            gridSize: 8,
            type: "select",
            placeholder: "Allocate device to Location",
            label: "Location",
            options: {
              items: [],
              action: "locations/getLocationForm",
              text: "name",
              value: "uuid"
            }
          },
          description: {
            value: "description",
            gridSize: 8,
            type: "textarea",
            placeholder: "Description",
            options: {
              counter: 1000,
              hint: "You can always edit predefined description"
            }
          },
        }
      },
        {
          title: "Tags",
          inputs: {
            tag_uuids: {
              value: "tag_uuids",
              gridSize: 8,
              type: "tags",
              placeholder: "Edit tags for your device",
              label: "Tags",
              options: {
                items: [],
                action: "tags/getTags",
                counter: 20,
                text: "name",
                value: "uuid"
              }
            }
          }
        }],
    }
  }),
};
</script>

<style scoped></style>
