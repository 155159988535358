import axiosInstance from "@/services/api/instance";
import store from "@/store";

export default{
    //this is for performance.vue
    get(data){
        return axiosInstance.get(`/space/${store.getters["global/getSpace"]}/device/items/?${data}`);
    },
    //this is for activity.vue
    getFiltering(data){
        return axiosInstance.get(`/space/${store.getters["global/getSpace"]}/asset/items/?${data}`);
    },
    getFullDeviceInfo(uuid){
        return axiosInstance.get(`/space/${store.getters["global/getSpace"]}/device/${uuid}/`);
    },
    getFullTelemetryInfo(uuid){
        return axiosInstance.post(`/space/${store.getters["global/getSpace"]}/device/${uuid}/services-telemetry-refresh/`);
    },
    exportPerformance(value){
         return axiosInstance.get(`/space/${store.getters["global/getSpace"]}/asset/items/export/?${value}`)
    }
}