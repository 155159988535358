<template>
  <div v-if="activePeripheral && activePeripheral.device_type_name !== 'Display'">
    <v-card
        v-if="activePeripheral.manufacturer !== ''"
        height="72"
        class="px-4 pb-5 pt-3 "
        flat>
      <v-card-text class="pa-0">
        <span class="subtitle-2 mb-2 text-secondary font-weight-regular d-block">Manufacturer</span>
        <v-list-item-title class="subtitle-2 text-primary font-weight-regular">
          {{ activePeripheral.manufacturer }}
        </v-list-item-title>
      </v-card-text>
    </v-card>
    <v-divider v-if="activePeripheral.manufacturer !== ''"/>
    <v-card
        v-if="activePeripheral.model !== ''"
        height="72"
        class="px-4 pb-5 pt-3 "
        flat>
      <v-card-text class="pa-0">
        <span class="subtitle-2 mb-2 text-secondary font-weight-regular d-block">Model</span>
        <v-list-item-title class="subtitle-2 text-primary font-weight-regular">
          {{ activePeripheral.model }}
        </v-list-item-title>
      </v-card-text>
    </v-card>
    <v-divider v-if="activePeripheral.model !== ''"/>
    <v-card
        v-if="activePeripheral.communication_protocol !== ''"
        height="72"
        class="px-4 pb-5 pt-3 "
        flat>
      <v-card-text class="pa-0">
        <span class="subtitle-2 mb-2 text-secondary font-weight-regular d-block">Communication Protocol</span>
        <v-list-item-title class="subtitle-2 text-primary font-weight-regular">
          {{ activePeripheral.communication_protocol.toUpperCase() }}
        </v-list-item-title>
      </v-card-text>
    </v-card>
    <v-divider  v-if="activePeripheral.communication_protocol !== ''"/>
    <v-card
        v-if="activePeripheral.currency !== ''"
        height="72"
        class="px-4 pb-5 pt-3 "
        flat>
      <v-card-text class="pa-0">
        <span class="subtitle-2 mb-2 text-secondary font-weight-regular d-block">Currency</span>
        <v-list-item-title class="subtitle-2 text-primary font-weight-regular">
          {{ activePeripheral.currency }}
        </v-list-item-title>
      </v-card-text>
    </v-card>
    <v-divider v-if="activePeripheral.currency !== ''"/>
    <v-card
        v-if="activePeripheral.serial_number !== ''"
        height="72"
        class="px-4 pb-5 pt-3 "
        flat>
      <v-card-text class="pa-0">
        <span class="subtitle-2 mb-2 text-secondary font-weight-regular d-block">Serial Number</span>
        <v-list-item-title class="subtitle-2 text-primary font-weight-regular">
          {{ activePeripheral.serial_number }}
        </v-list-item-title>
      </v-card-text>
    </v-card>
    <v-divider v-if="activePeripheral.serial_number !== ''"/>
    <v-card
        v-if="activePeripheral.firmware !== ''"
        height="72"
        class="px-4 pb-5 pt-3 "
        flat>
      <v-card-text class="pa-0">
        <span class="subtitle-2 mb-2 text-secondary font-weight-regular d-block">Firmware</span>
        <v-list-item-title class="subtitle-2 text-primary font-weight-regular">
          {{ activePeripheral.firmware }}
        </v-list-item-title>
      </v-card-text>
    </v-card>
    <v-divider v-if="activePeripheral.serial_number !== '' && activePeripheral.protocol_details"/>
    <v-card
        v-if="activePeripheral.protocol_details !== '' && activePeripheral.protocol_details"
        height="72"
        class="px-4 pb-5 pt-3 "
        flat>
      <v-card-text class="pa-0">
        <span class="subtitle-2 mb-2 text-secondary font-weight-regular d-block">Protocol Details</span>
        <v-list-item-title class="subtitle-2 text-primary font-weight-regular">
          {{ activePeripheral.protocol_details }}
        </v-list-item-title>
      </v-card-text>
    </v-card>
    <v-divider v-if="activePeripheral.serial_number !== ''"/>
    <div class="py-4 pl-4">
      <CommentBox @comment-submitted="receiveCommentEmit"/>
      <v-row  v-for="(item, i) in peripheralCommentsGetter" :key="i">
          <v-col class="my-0 py-0">
            <CommentItem @delete-comment="receiveCommentDeleteAction" :data="item"/>
          </v-col>
      </v-row>
    </div>
  </div>
  <div class="pl-4 py-4" v-else-if="activePeripheral && activePeripheral.device_type_name === 'Display'">
      <CommentBox @comment-submitted="receiveCommentEmit"/>
      <v-row  v-for="(item, i) in peripheralCommentsGetter" :key="i">
          <v-col class="my-0 py-0">
            <CommentItem @delete-comment="receiveCommentDeleteAction" :data="item"/>
          </v-col>
      </v-row>
  </div>
  <div v-else>
    <v-card
        height="72"
        class="pl-4 pb-5 pt-3 "
        flat>
      <v-card-text class="pa-0">
        <span class="subtitle-2 mb-2 text-secondary font-weight-regular d-block">Peripherals</span>
        <v-list-item-title class="subtitle-2 text-primary font-weight-regular">
          {{ device.displays.length }} displays, {{ device.peripherals.length }} misc
        </v-list-item-title>
      </v-card-text>
    </v-card>
    <v-divider/>
    <v-card
        height="72"
        class="px-4 pb-5 pt-3 "
        flat>
      <v-card-text class="pa-0">
        <span class="subtitle-2 mb-2 text-secondary font-weight-regular d-block">Primary Display</span>
        <v-list-item-title class="subtitle-2 text-primary font-weight-regular">
          {{ device.displays.find(display => display.primary === true).name }}
        </v-list-item-title>
      </v-card-text>
    </v-card>
    <v-divider/>
    <v-card
        height="72"
        class="px-4 pb-5 pt-3 "
        flat>
      <v-card-text class="pa-0">
        <span class="subtitle-2 mb-2 text-secondary font-weight-regular d-block">Configured Apps</span>
        <v-list-item-title class="subtitle-2 text-primary font-weight-regular">
          {{ totalApplications }}
        </v-list-item-title>
      </v-card-text>
    </v-card>
    <v-divider/>
    <v-card
        height="72"
        class="px-4 pb-5 pt-3 "
        flat>
      <v-card-text class="pa-0">
        <span class="subtitle-2 mb-2 text-secondary font-weight-regular d-block">Disabled Displays</span>
        <v-list-item-title class="subtitle-2 text-primary font-weight-regular">
          {{ getDisabledDisplays(device) }}
        </v-list-item-title>
      </v-card-text>
    </v-card>
    <v-divider/>
    <v-card
        height="72"
        class="px-4 pb-5 pt-3 "
        flat>
      <v-card-text class="pa-0">
        <span class="subtitle-2 mb-2 text-secondary font-weight-regular d-block">Disabled Misc</span>
        <v-list-item-title class="subtitle-2 text-primary font-weight-regular">
          {{ getDisabledMisc(device) }}
        </v-list-item-title>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import Devices from '../../../../services/api/Devices';
import CommentBox from '../../../Comments/CommentBox.vue';
import CommentItem from '../../../Comments/CommentItem.vue';

export default {
  name: "PeripheralInfo",
  components: {
    CommentBox,
    CommentItem
    
  },
  props: ['device'],
  data: function () {
    return {
       peripheral: {},
       commentValue: ""
    }
  },
  watch: {
  activePeripheral: {
    handler(val){
      this.peripheral = val;
      if(val.device_type_name === 'Display')
        {
          this.getDisplayComments(this.$props.device.uuid, val.uuid);
        }
      else
        {
          this.getPeripheralsComment(this.$props.device.uuid, val.uuid);
        }
    },
  },
  },
  computed: {
    ...mapGetters({ activePeripheral: "devices/activePeripheral", peripheralCommentsGetter: "devices/peripheralCommentsGetter", accountUuid:"accounts/getAccountUuid"}),
    cardReader(){
      return this.activePeripheral.device_type_name;
    },
    totalApplications() {
      // Check if there are displays and calculate the total number of applications
      if (this.device.displays.length > 1) {
        return this.device?.displays?.reduce((total, display) => {
          return total + (display.applications ? display.applications.length : 0);
        }, 0);
      } else if (this.device.displays.length === 1) {
        return this.device.displays[0].applications.length;
      }
      return 0; // Default return if no displays are found
    }
  },
  mounted(){
    this.getDisabledDisplays(); 
    this.getDisabledMisc(); 
  },
  methods: {
    getDisabledDisplays(device) {
      console.log(device);
      return device.displays.filter(display => display.disabled === true).map(display => display.name).join(", ") || "None";
    },
    getDisabledMisc(device) {
      return device.peripherals.filter(peripheral => peripheral.enabled === false).map(peripheral => peripheral.name).join(", ") || "None";
    },
    getDisplayComments(device_uuid, peripheral_uuid){
      try {
        Devices.getCommentDisplay(device_uuid, peripheral_uuid).then(response => {
          this.$store.commit('devices/SET_PERIPHERAL_COMMENTS', response.data.comments)
        }) 
      } catch (error) {
        console.log(error)
      }
    }, 
    getPeripheralsComment(device_uuid, peripheral_uuid){
      try {
        Devices.getCommentPeripheral(device_uuid, peripheral_uuid).then(response => {
          this.$store.commit('devices/SET_PERIPHERAL_COMMENTS', response.data.comments);
        })
        } catch (error) {
          console.log(error);
      }
    },
    receiveCommentEmit(data){
      if(this.peripheral.device_type_name === 'Display'){
        let uuid = this.$props.device.uuid;
        let display_uuid = this.peripheral.uuid;
        let accountUuid = this.accountUuid;
        Devices.addCommentDisplay(uuid, display_uuid, accountUuid, data).then(()=>{
          this.getDisplayComments(this.$props.device.uuid, this.peripheral.uuid);
        })
      }
      else {
       let device_uuid = this.$props.device.uuid;
       let peripheral_uuid =  this.peripheral.uuid;
       let accountUuid = this.accountUuid;
       Devices.addCommentPeripheral(device_uuid, peripheral_uuid, accountUuid, data).then(()=>{
          this.getPeripheralsComment(this.$props.device.uuid, this.peripheral.uuid);
        })
      }
    },
    receiveCommentDeleteAction(data){
      if(this.peripheral.device_type_name === 'Display'){
        let display_uuid = this.peripheral.uuid;
        let comment_uuid = data;
        Devices.deleteCommentDisplay(display_uuid, comment_uuid).then(()=>{
          this.getDisplayComments(this.$props.device.uuid, this.peripheral.uuid);
        })
      }
      else {
       let peripheral_uuid = this.peripheral.uuid;
       let comment_uuid =  data;
       Devices.deleteCommentPeripheral(peripheral_uuid, comment_uuid).then(()=>{
          this.getPeripheralsComment(this.$props.device.uuid, this.peripheral.uuid);
        })
      }
    },
  }
}
</script>

<style scoped>

</style>