<template>
  <div class="fill-height text-center">
    <v-dialog v-model="dialog"
              max-width="1176"
              class="ma-12">
      <v-card tile
              flat
              class="mb-5">
        <v-img :src="getDeviceScreenshot"/>
      </v-card>
      <v-alert v-model="dialog"
               dense
               dark
               color="greyAlert"
               class="px-3 py-0 mx-auto"
               max-width="617"
               style="position: sticky">
        <v-row align="center">
          <v-col class="grow">
            Screenshot is available!
          </v-col>
          <v-col class="shrink">
            <v-btn @click="openLink(getDeviceScreenshot)"
                   icon>
              <v-icon>{{ icons.mdiDownload }}</v-icon>
            </v-btn>
          </v-col>
          <v-col class="shrink">
            <v-btn  @click="() => openInNewTab(getDeviceScreenshot)" 
                   icon>
              <v-icon>{{ icons.mdiOpenInNew }}</v-icon>
            </v-btn>
          </v-col>
          <v-divider vertical/>
          <v-col class="shrink">
            <v-btn @click="closeDialog"
                   icon>
              <v-icon>{{ $vuetify.icons.values.close }}</v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-alert>
    </v-dialog>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { mdiDownload, mdiOpenInNew } from "@mdi/js";

export default {
  name: 'DeviceScreenshot',
  data() {
    return {
      dialog: false,
      icons: {
        mdiDownload,
        mdiOpenInNew,
      },
    };
  },
  computed: {
    ...mapGetters('devices', ['getDeviceScreenshot']),
  },
  methods: {
    openLink(link) {
      const a = document.createElement('a');
      a.href = link;
      a.download = 'screenshot.jpg';
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    },
    openInNewTab(link) {
      console.log('Link:', link);

      const newTab = window.open('', '_blank');

      if (!newTab) {
        alert('Popup blocked! Please allow popups for this site.');
        return;
      }

      const safeLink = link.replace(/"/g, '&quot;').replace(/'/g, '&#39;');

      newTab.document.write(`
        <html>
          <head>
            <title>Screenshot Viewer</title>
            <style>
              body, html {
                margin: 0;
                padding: 0;
                height: 100%;
                background: #000;
                display: flex;
                justify-content: center;
                align-items: center;
              }
              img {
                max-width: 100%;
                max-height: 100%;
              }
            </style>
          </head>
          <body>
            <img src="${safeLink}" alt="Screenshot" />
          </body>
        </html>
      `);

      newTab.document.close();
    },
    closeDialog() {
      this.dialog = false;
    },
  },
  watch: {
    getDeviceScreenshot() {
      if (this.getDeviceScreenshot) {
        this.dialog = true;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep {
  .v-dialog {
    box-shadow: none;
    border-radius: 0;
  }
}
</style>