<template>
  <v-dialog
      v-model="isShown"
      no-click-animation
      scrollable
      :overlay-color="isDarkTheme ? '#424242': 'overlaycolor'"
      overlay-opacity="0.3"
      transition="slide-x-reverse-transition"
      max-width="1080"
      persistent
      @click:outside="closeDialog()">
    <v-card
        flat
        tile
        class="mr-sm-15 overflow-md-y-hidden overflow-x-hidden px-0 px-md-12 pt-8 pb-0">
      <AddDeviceRunner 
       v-if="$route.params.action === 'addDeviceRunner'"/>
      <DetectDevices v-if="$route.params.action === 'detectDevices'"/>
      <AddLocation 
      v-else-if="$route.params.action === 'addLocation'"/>
      <InviteUser v-if="$route.params.action === 'inviteUser'"/>
      <LocationInfoEdit
          v-else-if="
          $route.params.location_type === 'location' &&
          $route.params.uuid &&
          $route.params.action === 'edit'
        "/>
      <LocationInfo
          v-else-if="$route.params.location_type === 'location' && $route.params.uuid"
          type="location"
      />
      <DeviceInfoEdit
          v-else-if="
          ($route.params.device_type === 'device' &&
          $route.params.action === 'edit' &&
          $route.params.uuid) || ($route.params.monitoring_type === 'device' && $route.params.action === 'edit' && $route.params.uuid)
        "
      />
      <DeviceInfo
          v-else-if="$route.params.device_type === 'device' && 
          $route.params.uuid || $route.name === 'device_location' && 
          type === 'device'"
          type="device"
          :channel="channel"
      />
      <CameraInfo
          v-else-if="$route.params.device_type === 'camera' && 
          $route.params.uuid"
          type="camera"
      />  
      <MonitoringInfo
        v-else-if="$route.params.monitoring_type === 'device' && $route.params.uuid"
        type="device"
        :channel="channel"
      /> 
      <edit-group v-else-if="$route.params.action === 'edit' && this.$route.params.action === 'edit'"/>
      <AddGroup v-else-if="$route.params.action === 'addGroup'"></AddGroup>
    <GroupInfo type="group" v-else-if="this.route === 'group'"/>
    </v-card>
  </v-dialog>
</template>
<script>
import DeviceInfo from "./Devices/Device/DeviceInfo";
import AddGroup from "./Groups/AddGroups.vue";
import CameraInfo from "./Devices/Camera/CameraInfo";
import AddDeviceRunner from "./Devices/AddDeviceRunner";
import DetectDevices from "./Devices/DetectDevices";
import AddLocation from "./Locations/AddLocation";
import LocationInfo from "./Locations/LocationInfo";
import LocationInfoEdit from "./Locations/LocationInfoEdit";
import {mapActions, mapGetters} from "vuex";
import DeviceInfoEdit from "./Devices/Device/DeviceInfoEdit";
import InviteUser from "./Accounts/InviteUser";
import MonitoringInfo from "./Monitoring/MonitoringData/MonitoringInfo.vue";
import GroupInfo from './Groups/GroupInfo.vue';
import EditGroup from './Groups/EditGroup.vue';
const v4 = new RegExp(/^[0-9A-F]{8}-[0-9A-F]{4}-4[0-9A-F]{3}-[89AB][0-9A-F]{3}-[0-9A-F]{12}$/i);
const v5 = new RegExp(/^[0-9A-F]{8}-[0-9A-F]{4}-[5][0-9A-F]{3}-[89AB][0-9A-F]{3}-[0-9A-F]{12}$/i);
export default {
  name: "FlyoutMenu",
  components: {
    InviteUser,
    DeviceInfoEdit,
    DeviceInfo,
    CameraInfo,
    LocationInfo,
    LocationInfoEdit,
    AddDeviceRunner,
    DetectDevices,
    AddLocation,
    MonitoringInfo,
    AddGroup,
    GroupInfo,
    EditGroup
  },
  data: () => ({
    channel: null,
    route: "",
    type: "",
  }),
  computed: {
    ...mapGetters("devices",["getFlyoutEditDevice", "getSelectedDevice"]),
    ...mapGetters("locations", ["getFlyoutEditLocation"]),
    isShown() {
      return !!this.$route.params.device_type || !!this.$route.params.action
    },
    isDarkTheme() {
      return this.$vuetify.theme.dark || false;
    },
  },
  methods: {
    ...mapActions({
      getDevice: "devices/getSpecificDevice",
      getDevices: "devices/getDevices",
      getLocation: "locations/getLocationByUUID",
      addAlert: "global/addAlert",
      setSpace: "global/setSpace",
      getDisplays: "devices/getDeviceDisplays",
      getAvailableApps: "devices/getAvailableApplications",
      fetchGroup: "groups/fetchGroup"
    }),

    closeDialog() {
      // TODO: istraziti go(n-1) u slucaju fromLocation param-a.
      this.$store.commit("locations/SET_LOCATION_DEVICES", []);
      if(this.$route.name === 'location' && this.$route.params.action === 'view'){
          this.$router.replace(`/locations`)
          }
      if(this.$route.name === 'device_location' && window.history.length === 2){
        console.log(window.history.length)
        this.$router.replace(`/locations`)
      }
      if (this.$route.name === 'location' || 
          this.$route.name === 'device' || 
          this.$route.name === 'account' || 
          this.$route.name === 'device_location' || 
          this.$route.name === 'monitoring' || 
          this.$route.name === 'activity' || 
          this.$route.params.action === "detectDevices" || 
          this.$route.params.action === "addGroup" || 
          this.route === 'group' ) {
        if (this.$route.params.fromLocation) {
          this.$router.replace(`/devices`)
        }else if(this.route === 'group'){
          if(window.history.length === 1){
            this.$router.replace(`/groups`)
            this.$store.commit("groups/setGroup", '');
          }else{
            this.$router.go(-1);
            this.$store.commit("groups/setGroup", '');
          }
        }
        else if(this.$route.params.action === 'detectDevices'){
          this.$store.commit('locations/SET_DETECTED_DEVICES', []);
          this.$store.commit("devices/SET_NO_DETECT_DEVICES", false);
        } else {
          if (window.history.length > 2) { 
            this.$router.go(-1);
            this.$store.commit('monitoring/SET_SELECTED_DEVICE', "");
          } else if(this.$route.name === 'monitoring') {
            this.$router.replace(`/performance`)
          }else if(this.$route.name === 'activity') {
            this.$router.replace(`/activity`)
          }else if(this.route === 'device_location'){
          this.$router.replace(`/locations/location/view/${this.getSelectedDevice.location_uuid}`)
          }else{
            this.$router.replace(`/${this.$route.name}s`);
          }
        }
      }
      if (this.$route.name === 'accounts_action' || this.$route.name === 'location_action' || this.$route.name === 'device_action') {
        if (window.history.length > 0) {
          this.$router.go(-1);
        } else if(this.$route.params.action === 'detectDevices'){
          this.$store.commit('locations/SET_DETECTED_DEVICES', []);
          this.$store.commit('locations/SET_DETECTED_DEVICES', []);
          this.$store.commit("devices/SET_NO_DETECT_DEVICES", false);
        }else {
          switch (this.$route.name) {
            case "location_action":
              this.$router.replace("/locations");
              break;
            case "device_action":
              this.$router.replace("/devices");
              break;
            default:
              this.$router.replace("/");
              break;
          }
        }
      }
      this.channel.unsubscribe(this.channel.name);
      this.$store.commit('devices/SET_SELECTED_DEVICE', "");
      this.$store.commit('devices/SET_ACTIVE_PERIPHERAL', "");
      this.$store.commit('monitoring/SET_SELECTED_DEVICE', "");
      this.$store.commit("devices/SET_LOADER_SCREENSHOT", false);      
      this.$store.commit("devices/SET_LOADER_RD", false);      
      this.$store.commit("devices/SET_LOADER_RSSH", false);    
      this.$store.commit("devices/SET_DEVICE_COMMENTS", {});
      this.getDevices();
      this.channel.unbind();
      this.channel.unsubscribe();
    },
    subscribe(type, uuid) {
      this.channel = this.$pusher.subscribe(`${type}_${uuid}`);
      if (type === "location") {
        this.channel.bind("locationDetailsUpdate", (data) => {
          console.log(data)
          switch (data.type) {
            case "devicesDetected":
            this.addAlert({
              type: "info",
              message: "Cameras detected",
            });
            break;
          case "noDevicesDetected":
            this.addAlert({
              type: "error",
              message: "Camera scan unavailable."
            })
            break;
          }
        });
      } else if (type === "device" || (this.route === 'device_location' && type === 'device') || this.route === 'monitoring' && (type === 'device')) {
        this.channel.bind("deviceDetailsUpdate", (data) => {
          switch (data.type) {
            case "screenshotNotification":
              this.addAlert({
                color: "overlaycolor",
                constant: true,
                message: "Screenshot taken",
                button: {
                  showCloseButton: true,
                  type: 'screenshot',
                  text: 'View',
                  data: data.screenshotUrl
                },
                linkType: 'screenshot'
              }).then(() => {
                this.$store.commit("devices/SET_LOADER_SCREENSHOT", false);
              })
              break;
            case "screenshotNotificationError":
            this.addAlert({
                  type: "error",
                  message: data.errorMessage,
                }).then(() => {
                this.$store.commit("devices/SET_LOADER_SCREENSHOT", false);
              });
              break;
            case "DDMConfNotification":
              if (data.severity === "error") {
                this.addAlert({
                  type: "error",
                  message: data.message,
                });
              }else if (data.severity === "normal") {
                this.addAlert({
                  type: "success",
                  message: data.message
                }).then(() => {
                    this.getDisplays(this.getSelectedDevice.uuid);
                    this.getAvailableApps(this.getSelectedDevice.uuid);
                })
              } else {
                return;
              }
              break;
            case "reverseSshNotification":
              this.addAlert({
                type: "success",
                color: "overlaycolor",
                constant: true,
                message: "RSSH Port: " + data.reverseSshPort,
                button: {
                  showCloseButton: true,
                  type: 'copy',
                  text: 'Copy',
                  data: data.reverseSshPort
                }
              }).then(() => {
                this.$store.commit("devices/SET_LOADER_RSSH", false);
              })
              break;
            case "remoteDesktopNotification":
              this.addAlert({
                type: "success",
                color: "overlaycolor",
                constant: true,
                button: {
                  showCloseButton: true,
                  type: 'open',
                  data: data.remoteDesktopUrl,
                  text: 'Connect'
                },
                message: "Remote Desktop available"
              }).then(() => {
                this.$store.commit("devices/SET_LOADER_RD", false);
              })
              break;
            case "peripheralsDetected":
              this.addAlert({
                type: "success",
                message: "New peripherals detected."
              }).then(() => {
                let params = { route: 'device', uuid: this.getSelectedDevice.uuid };
                  this.getDevice(params).then(response =>{
                    let payload = {
                    "uuid": this.getSelectedDevice.uuid,
                    "data": response
                    };
                    this.$store.commit("devices/UPDATE_DEVICE_BY_UUID", payload);//update mutation 
                    this.getDisplays(this.getSelectedDevice.uuid);
                    this.getAvailableApps(this.getSelectedDevice.uuid);
                  })
                  })
              break;
            case "noPeripheralsDetected":
              this.addAlert({
                type: "warning",
                message: "No new peripherals detected."
              }).then(() => {
                let params = { route: 'device', uuid: this.getSelectedDevice.uuid };
                  this.getDevice(params).then(response =>{
                    let payload = {
                    "uuid": this.getSelectedDevice.uuid,
                    "data": response
                    };
                    this.$store.commit("devices/UPDATE_DEVICE_BY_UUID", payload);//update mutation 
                    this.getDisplays(this.getSelectedDevice.uuid);
                    this.getAvailableApps(this.getSelectedDevice.uuid);
                  })
              })
              break;
            case "displaysDetected":
              this.getDisplays(this.getSelectedDevice.uuid);
              break;
            case "noDisplaysDetected":
              this.addAlert({
                type: "warning",
                message: "No new displays detected."
              }).then(() => {
                let params = { route: 'device', uuid: this.getSelectedDevice.uuid };
                  this.getDevice(params).then(response =>{
                    let payload = {
                    "uuid": this.getSelectedDevice.uuid,
                    "data": response
                    };
                    this.$store.commit("devices/UPDATE_DEVICE_BY_UUID", payload);//update mutation 
                    this.getDisplays(this.getSelectedDevice.uuid);
                    this.getAvailableApps(this.getSelectedDevice.uuid);
                  })
              })

              break;
            case "offlineDeviceUpdated":
              this.addAlert({
                type: "warning",
                message: "Configuration updated, but device is offline",
              });
              break;
            case "deviceDisabled":
              this.addAlert({
                type: "error",
                message: "Device is disabled.",
              });
              break;
            case "noAvailableAppRunners":
              this.addAlert({
                type: "error",
                message: "Device has no app runners available."
              });
              break;
            case "nonInstalledDevice":
              this.addAlert({
                type: "error",
                message: "Action failed. Device not installed."
              });
              break;
            case "fullTelemetryUpdated":
            {
              let params = { route: 'device', uuid: this.getSelectedDevice.uuid };
              this.getDevice(params).then(response =>{
                let payload = {
                "uuid": this.getSelectedDevice.uuid,
                "data": response
                };
                this.$store.commit("devices/UPDATE_DEVICE_BY_UUID", payload);//update mutation 
              })
              break;
            }
            case "visionEnabledStatusNotification": {
          this.addAlert({
            type: data.severity === 'error' ? 'error' : 'success',
            message: data.message,
          });

          let visionState;

          if (data.severity === 'error') {
            visionState = data.previous_status || 'disabled';  
          } else {
            // ako je poruka success i prethodno stanje je disable_pending → sad treba biti disabled
            // ako je prethodno stanje enable_pending → sad je enabled
            const currentDevice = this.getSelectedDevice;
            if (currentDevice.vision_enabled === 'disable_pending') {
              visionState = 'disabled';
            } else if (currentDevice.vision_enabled === 'enable_pending') {
              visionState = 'enabled';
            } else {
              visionState = 'enabled'; // fallback ako nešto nije jasno
            }
          }

          this.$store.commit('devices/UPDATE_DEVICE_VISION_STATE', {
            uuid: data.deviceUuid,
            vision_enabled: visionState
          });
          break;
          }
          }
        });
      }

    },
  selectItem(route, type, uuid) {
      if (route === 'location' || 
          route === 'device' ||  
          route === 'device_location' || 
          route === 'monitoring' || 
          route === 'activity' || 
          route === 'group') {
        if (route === 'location' && this.$route.params.tab === 'general') {
          this.getLocation(uuid);
        } else if(route === 'device_location') {
          let data = {
            route: 'device',
            space_uuid: this.$route.params.space_uuid,
            uuid: this.$route.params.uuid
          }
          this.subscribe('device', this.$route.params.uuid);
          this.getDevice(data);
        }else if(route === 'monitoring'){
          let data = {
            route: 'device',
            space_uuid: this.$route.params.space_uuid,
            uuid: this.$route.params.uuid
          }
          this.setSpace(data.space_uuid);
          this.subscribe(data.route, data.uuid);
          this.getDevice(data)
        }
        else if(route === 'activity'){
          let data = {
            route: 'device',
            space_uuid: this.$route.params.space_uuid,
            uuid: this.$route.params.uuid
          }
          this.setSpace(data.space_uuid);
          this.subscribe(data.route, data.uuid);
          this.getDevice(data)
        }
        else if(route === "device"){
          let data = {
            route: 'device',
            space_uuid: this.$route.params.space_uuid,
            uuid: this.$route.params.uuid
          }
          this.setSpace(data.space_uuid);
          this.subscribe(data.route, data.uuid);
          this.getDevice(data)

        }
        else if(route === "group"){
          this.fetchGroup(this.$route.params.uuid);
        }
      }
    },
  },
  created() {
    const {device_type, action, uuid, location_type, monitoring_type} = this.$route.params;
    const mainCategory = this.$route.name;
    this.route = mainCategory;
    this.type = device_type || 
                location_type || 
                monitoring_type;
    switch (mainCategory) {
      case "location":
        switch (action) {
          case "view":
            if (this.$route.params.uuid.toUpperCase().match(v4) || 
                this.$route.params.uuid.toUpperCase().match(v5) || 
                this.$route.params.uuid) {
              this.selectItem(mainCategory, location_type, uuid)
            }
            break;
        }
        break;
      case "device":
        switch (action) {
          case "view":
            this.selectItem(mainCategory, device_type, uuid)
            break;
        }
        break;
      case "device_location":
        switch (action) {
          case "view":
            this.selectItem(mainCategory, device_type, uuid)
            break;
        }
        break;
        case "monitoring":
          switch(action){
            case "view":
              this.selectItem(mainCategory, monitoring_type, uuid)
              break;
        }
        break;
        case "activity":
        switch(action){
            case "view":
              this.selectItem(mainCategory, device_type, uuid)
              break;
        }
        break;
        case "group":
        switch(action){
            case "view":
              this.selectItem(mainCategory, this.$route.params.uuid)
              break;
        }
    }
  },
  //beforeDestroy() {
    //this.closeDialog();
  //},
  // created() {
  //   this.type = this.$route.params.type;
  //   this.route = this.$route.name
  //   console.log(this.route)
  //   switch (this.route) {
  //     case "location":
  //       switch (this.type) {
  //         case "view":
  //           if (this.$route.params.uuid.toUpperCase().match(v4) || this.$route.params.uuid.toUpperCase().match(v5)) {
  //             this.selectItem(this.route, this.$route.params.type, this.$route.params.uuid)
  //           }
  //           break;
  //         case "edit":
  //           console.log("dosao edit")
  //           break;
  //       }
  //       break;
  //     case "device":
  //       switch (this.type) {
  //         case "view":
  //           if (this.$route.params.uuid.toUpperCase().match(v4) || this.$route.params.uuid.toUpperCase().match(v5)) {
  //             this.selectItem(this.route, this.$route.params.type, this.$route.params.uuid)
  //           }
  //           break;
  //       }
  //   }
  //   // if (
  //   //   this.$route.params.type &&
  //   //   this.$route.params.uuid &&
  //   //   (this.$route.params.uuid.toUpperCase().match(v4) || this.$route.params.uuid.toUpperCase().match(v5))
  //   // ) {
  //   //   this.selectItem(this.$route.params.type, this.$route.params.uuid);
  //   // }
  // },
  watch: {
    $route() {
      const {type, action, uuid} = this.$route.params;
      const mainCategory = this.$route.name;
      this.route = mainCategory;
      this.type = type;
      switch (mainCategory) {
        case "location":
          switch (action) {
            case "view":
              if (this.$route.params.uuid.toUpperCase().match(v4) || 
                  this.$route.params.uuid.toUpperCase().match(v5) || 
                  this.$route.params.uuid) {
                this.selectItem(mainCategory, action, uuid)
              }
              break;
          }
          break;
        case "device":
          switch (action) {
            case "view":
              if (uuid !== this.getSelectedDevice?.uuid) {
                this.selectItem(mainCategory, action, uuid)
              }
              break;
          }
          break;
        case "device_location":
          switch (action) {
            case "view":
              if (uuid !== this.getSelectedDevice?.uuid) {
                this.selectItem(mainCategory, action, uuid)
              }
              break;
          }
          break;
          case "monitoring":
          switch(action){
            case "view":
            if (uuid !== this.getSelectedDevice?.uuid) {
                this.selectItem(mainCategory, action, uuid)
              }
              break;
          }
          break;
          case "activity":
          switch(action){
            case "view":
            if (uuid !== this.getSelectedDevice?.uuid) {
                this.selectItem(mainCategory, action, uuid)
              }
              break;
          }
          break;
          case "group":
          switch(action){
            case "view":
                this.selectItem(mainCategory, action, uuid);
              break;
          }
          break;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep {
  .v-dialog--scrollable {
    position: absolute;
    right: 0;
    margin: 0;
    border-radius: 0;
  }

  .v-dialog:not(.v-dialog--fullscreen) {
    min-height: 100%;
    box-shadow: none;
  }
}

.mr-sm-15 {
  margin-right: 64px;
}

@media screen and (max-width: 600px) {
  .mr-sm-15 {
    margin-right: 0 !important;
  }
}
.overlay-dark{
  background-color: #757575 !important;
}
</style>
