import Monitoring from "../../services/api/Monitoring";


const state = {
    currentPage: 1,
    totalPages: 0,
    isLoading: false,
    getFullInfoData: {},
    latest_devices_added: [],
    latest_devices_edited: [],
    latest_devices_connected: [],
    disconnected_devices: [],
    connected_devices_online: [],
    connected_devices_offline: [],
    /*latestQueryAdded:{
      ordering: "created_at",
      page_size: 30,
      page: 1,
      name: null,
      location_uuid: null,
      device_category_uuid: null
    },
    latestQueryConnected:{
      connected_status: "connected",
      ordering: "instalation_date",
      page: 1,
      page_size: 30,
      name: null,
      location_uuid: null
    },
    latestQueryEdited:{
      connected_status: "connected",
      ordering: "-updated_at",
      page: 1,
      page_size: 30,
      name: null,
      location_uuid: null,
    },
    disconnectedQuery: {
      connected_status: "disconnected",
      online: "False",

    },*/
    latestQueryAdded: 'ordering=-created_at',
    latestQueryConnected: 'connected_status=connected&ordering=-installation_date',
    latestQueryEdited: 'connected_status=connected&ordering=-updated_at',
    disconnectedQuery: 'connected_status=disconnected&online=False&ordering=-last_seen', 
    connectedQueryOnline: 'connected_status=connected&online=True&ordering=-last_seen',
    connectedQueryOffline: 'connected_status=connected&online=False&ordering=-last_seen',
    monitorDevices: [],
    domain: "top",
    ordering: 'page=1&page_size=36',
    filterMonitor:{
      name: "",
      location_uuid: [],
      device_type_uuid: [],
      page: 1,
      page_size: 36,
      combined_status: []
    },
    totalMonitorDevices: 0,
    devicesMonitorUpdated: 0,
    devicesMonitor: [
      {
        text: 'Cpu.Avg.util',
        value: 'cpu_status',
         section: {
          warning:[],
          critical:[]
        }
      },
      {
        text: 'CPU Temp.',
        value: "thermal_status",
         section: {
          warning:[],
          critical:[]
        }
      },
      {
        text: 'Uptime',
        value: "uptime",
         section: {
          warning:[],
          critical:[]
        }
      },
      {
        text: 'Memory Use',
        value: "memory_status",
         section: {
          warning:[],
          critical:[]
        }
      },
      {
        text: 'Disk Size',
        value: "storage_status",
        section: {
          warning:[],
          critical:[]
        }
      },
      {
        text: 'Disk Health',
        value: null,
         section: {
          warning:[],
          critical:[]
        }
      },
      {
        text: 'GPU Util',
        value: null,
         section: {
          warning:[],
          critical:[]
        }
      },
      {
        text: 'Network (Ok, In/Out)',
        value: "network_status",
         section: {
          warning:[],
          critical:[]
        }
      },

    ],
    PeripehralsMonitor: [
      {
        text: 'Bill Acceptor',
        value: 'bill',
         section: {
          warning:[],
          critical:[]
        }
      },
      {
        text: 'Coin acceptor',
        value: "coin",
         section: {
          warning:[],
          critical:[]
        }
      },
      {
        text: 'Paylink',
        value: "uptime",
         section: {
          warning:[],
          critical:[]
        }
      },
      {
        text: 'Pos Printer',
        value: "memory_status",
         section: {
          warning:[],
          critical:[]
        }
      },
      {
        text: 'Displays',
        value: "display",
        section: {
          warning:[],
          critical:[]
        }
      },
      {
        text: 'Door Switches',
        value: "switch",
        section: {
          warning:[],
          critical:[]
        }
      },
      {
        text: 'Card Reader',
        value: 'card',
         section: {
          warning:[],
          critical:[]
        }
      },
    ],
}

const getters = {
    isNextPageAvailable: state => state.currentPage <= state.totalPages,
    getTotalMonitorDevicesGetter: state => state.totalMonitorDevices,
    getMonitorDevicesGetter: (state) => {
        return state.monitorDevices;
      },
    getMonitorDevicesNumberGetter: (state) => {
        return state.monitorDevices.length();
      },
    getLatestDevicesAdded(state){
      return state.latest_devices_added;
    },
    getLatestDevicesEdited(state){
      return state.latest_devices_edited;
    },
    getLatestDevicesConnected(state){
      return state.latest_devices_connected;
    },
    getDisconnectedDevices(state){
      return state.disconnected_devices;
    },
    getConnectedDevicesOnline(state){
      return state.connected_devices_online;
    },
    getConnectedDevicesOffline(state){
      return state.connected_devices_offline;
    },
    getLatestQuery(state){
      return state.latestQuery;
    },
    getConnectedQuery(state){
      return state.connectedQuery;
    },
    getDisconnectedQuery(state){
      return state.disconnectedQuery;
    },
    getMonitorHeadings: (state) => {
      if(state.domain === "top"){
        return state.devicesMonitor
      }else{
        return state.PeripehralsMonitor
      }
      
      },
    getFilterMonitoring: (state) => {
        return state.filterMonitor;
      },
    getDomain: (state) =>{
      return state.domain;
    },
    getSelectedDevice: (state) => {
      return state.getFullInfoData;
    }
}
const actions = {
  getMonitorDevices({ commit, dispatch, state }) {
    if (state.isLoading) {
        console.log("Still loading previous request or all data loaded.");
        return;
    }
    console.log(state.filterMonitor)
    commit('SET_LOADING', true);
    const filterQuery = Object.entries(state.filterMonitor).reduce((acc, [key, value]) => {
        if (value && (Array.isArray(value) ? value.length > 0 : true)) {
            acc += `${key}=${encodeURIComponent(value)}&`;
        }
        return acc;
    }, '');

    const queryParams = `${filterQuery}`.slice(0, -1); // Removes the trailing '&'

    Monitoring.get(queryParams).then(response => {
        console.log("API response:", response.data); // Check the actual API response
        const { devices, pages_count, objects_count } = response.data;
        commit('SET_MONITOR_DEVICES', devices);
        dispatch('fillSections');
        commit('SET_TOTAL_PAGES', pages_count);
        commit('SET_CURRENT_PAGE', state.currentPage + 1);
        commit('SET_TOTAL_DEVICES', objects_count);
        commit('SET_LOADING', false);
    }).catch(error => {
        console.error('API fetch error:', error);
        commit('SET_LOADING', false);
    });
},
fillSections({ commit, getters }) {
  const data = [];
  for (let i = 0; i < getters.getMonitorDevicesGetter.length; i++) {
    const device = getters.getMonitorDevicesGetter[i];
    for (const key in device) {
      if (device[key] === 1 || device[key] === 2) {
        data.push(`${key}: ${device[key]}`);
      }
    }
  }
  for (let i = 0; i < state.devicesMonitor.length; i++) {
    const monitor = state.devicesMonitor[i];
    if (monitor.value) {
      const matchingSections = data.filter((item) => item.includes(monitor.value));
      const warningSections = matchingSections.filter((item) => item.endsWith('1'));
      const criticalSections = matchingSections.filter((item) => item.endsWith('2'));
      monitor.section.warning = warningSections;
      monitor.section.critical = criticalSections;
    }
  }

  commit('SET_MONITOR_HEADINGS', state.devicesMonitor);
},
updateFilterMonitor({ commit, state }, filter) {
        console.log(filter)
        const updatedFilter = {
          ...state.filterMonitor,
          location_uuid: filter.checkLocations,
          device_type_uuid: filter.checkDeviceCategory,
          combined_status: filter.checkCondition
        }
        commit('SET_FILTER_MONITOR', updatedFilter)
        console.log(updatedFilter);
      },
    setDomain({commit}, data){
      commit('SET_DOMAIN_NAME', data);
    },
    setLatestAded({ commit, state }) {
      return Monitoring.getFiltering(state.latestQueryAdded).then((response) => {
            commit('SET_LATEST_DEVICES_ADDED', response.data.devices);
        });
    },
    setLatestConnected({ commit, state }) {
        return Monitoring.getFiltering(state.latestQueryConnected).then((response) => {
            commit('SET_LATEST_DEVICES_CONNECTED', response.data.devices);
        });
    },
    setLatestEdited({ commit, state }) {
        return Monitoring.getFiltering(state.latestQueryEdited).then((response) => {
            commit('SET_LATEST_DEVICES_EDITED', response.data.devices);
        });
    },
    setDisconnectedDevices({ commit, state }) {
      return Monitoring.getFiltering(state.disconnectedQuery).then((response) => {
          commit('SET_DISCONNECTED_DEVICES', response.data.devices);
      }).catch((error) => {
          console.error("Failed to fetch disconnected devices:", error);
      });
  },  
    setConnectedOnline({ commit, state }) {
        return Monitoring.getFiltering(state.connectedQueryOnline).then((response) => {
            commit('SET_CONNECTED_DEVICES_ONLINE', response.data.devices);
        });
    },
    setConnectedOffline({ commit, state }) {
        return Monitoring.getFiltering(state.connectedQueryOffline).then((response) => {
            commit('SET_CONNECTED_DEVICES_OFFLINE', response.data.devices);
        });
    },
    
    appendToQuery({ state, commit, dispatch }, { queryKey, paramValue }) {
      let updatedQuery = state[queryKey];
    
      // Replace or append the parameters in the query
      const searchRegex = /search=[^&]*/;
      const locationRegex = /location_uuid=[^&]*/;
    
      // Remove existing search and location_uuid if present
      updatedQuery = updatedQuery
        .replace(searchRegex, '')
        .replace(locationRegex, '');
    
      // Append the new combined query params
      updatedQuery = `${updatedQuery}&${paramValue}`.replace(/&&+/g, '&').replace(/^&|&$/g, '');
    
      // Commit the updated query
      commit('SET_QUERY', { key: queryKey, value: updatedQuery });
    
      // Map the queryKey to corresponding data-fetching action
      const actionMap = {
        latestQueryAdded: 'setLatestAded',
        latestQueryConnected: 'setLatestConnected',
        latestQueryEdited: 'setLatestEdited',
        disconnectedQuery: 'setDisconnectedDevices',
        connectedQueryOnline: 'setConnectedOnline',
        connectedQueryOffline: 'setConnectedOffline',
      };
    
      const actionName = actionMap[queryKey];
      if (actionName) {
        dispatch(actionName);
      } else {
        console.warn(`No matching action found for queryKey: ${queryKey}`);
      }
    },
    
    
    
    
}

const mutations = {
    SET_FILTER_MONITOR(state, filter) {
      state.filterMonitor = filter
    },
    SET_MONITOR_DEVICES(state, devices) {
      // This completely replaces the existing monitorDevices array with the new one
      state.monitorDevices = devices;
    },
    APPEND_MONITOR_DEVICES(state, newDevices) {
        const existingIds = new Set(state.monitorDevices.map(device => device.uuid));
        newDevices.forEach(device => {
            if (!existingIds.has(device.uuid)) {
                state.monitorDevices.push(device);
            }
        });
    },
    SET_CURRENT_PAGE(state, page) {
      state.currentPage = page;
    },
    SET_TOTAL_PAGES(state, totalPages) {
        state.totalPages = totalPages;
    },
    SET_TOTAL_DEVICES(state, totalDevices) {
        state.totalMonitorDevices = totalDevices;
    },
    SET_LOADING(state, isLoading) {
        state.isLoading = isLoading;
    },
    SET_MONITOR_HEADINGS(state, payload){
      state.devicesMonitor = payload;
    },
    SET_DOMAIN_NAME(state, payload){
      state.domain = payload;
    },
    SET_SELECTED_DEVICE(state, payload){
      state.getFullInfoData = payload;
    },
    SET_LATEST_DEVICES_ADDED(state, payload){
      state.latest_devices_added = payload;
    },
    SET_LATEST_DEVICES_EDITED(state, payload){
      state.latest_devices_edited = payload;
    },
    SET_LATEST_DEVICES_CONNECTED(state, payload){
      state.latest_devices_connected = payload;
    },
    SET_DISCONNECTED_DEVICES(state, payload){
      state.disconnected_devices = payload;
    },
    SET_CONNECTED_DEVICES_ONLINE(state, payload){
      state.connected_devices_online = payload;
    },
    SET_CONNECTED_DEVICES_OFFLINE(state, payload){
      state.connected_devices_offline = payload;
    },
    SET_QUERY(state, { key, value }) {
      state[key] = value;
  }
  
}
export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};