<template>
    <div>
      <v-card class="pa-4 scrollable-card" height="90vh" elevation="0" outlined>
        <div class="d-flex justify-space-between">
          <v-card-title class="flex-grow-1">{{ editorData.name }}</v-card-title>
          <v-btn v-show="isUserSysdev" @click="deleteDialog" class="mt-2" elevation="0" color="text-capitalize error">Delete</v-btn>
        </div>
        <vue-json-editor
          v-model="editorData"
          mode="code"
          @change="handleDataChange"
          :expanded-on-start="true"
        ></vue-json-editor>
        <v-card-actions class="ml-0 pl-0 mt-0 pt-6">
          <v-spacer></v-spacer>
            <v-btn width="120" elevation="0" color="text-capitalize gray" @click="cancelModal">Cancel</v-btn>
            <v-btn  width="120"  elevation="0" color="text-capitalize primary" @click="updateRunner">Save</v-btn>
          </v-card-actions>
      </v-card>
      <v-dialog v-model="isDeleteDialog" width="400">
      <v-card class="pa-5">
            <v-row class="pl-5 pr-3">
          <v-card-title>Delete application</v-card-title>
          <v-card-actions class="ml-auto"><v-btn @click="deleteDialog" icon><v-icon>{{ icons.mdiClose }}</v-icon></v-btn></v-card-actions>
        </v-row>
            <v-card-text class="text-secondary">
              Are you sure you want to delete <b></b> application?
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                  class="cnl-btn px-8"
                  text
                  @click="deleteDialog"
              >Cancel
              </v-btn>
              <v-btn
                  class="red-btn px-8 ml-6"
                  text
                  @click="deleteRunner"
                 >
                Delete
              </v-btn>
            </v-card-actions>
          </v-card>
    </v-dialog>
    </div>
  </template>
  
  <script>
  import VueJsonEditor from 'vue-json-editor';
  import { mapActions, mapGetters } from 'vuex';
  import { mdiClose } from '@mdi/js';
  import Applications from '../../services/api/Applications';

  
  export default {
    name: "RunnerPreview",
    components: { VueJsonEditor },
    props: ['runnerId'],
    data() {
      return {
        isDeleteDialog: false,
        editorData: {}, // This will be bound to the JSON editor
        initialApplicationData: {},
        changesOnApp: {},
        currentPage: 1,
        pageSize: 35,
        icons: {
          mdiClose: mdiClose
        },
      };
    },
    computed: {
      ...mapGetters('runners', ['getCurrentRunner']),
      ...mapGetters('accounts', ['getUser']),
        isUserSysdev(){
          return this.getUser.sys_dev;
        }
    },
    watch: {
      runnerId: {
        immediate: true,
        handler(newVal) {
          this.fetchRunnersData(newVal);
        }
      },
      getCurrentRunner: {
        immediate: true,
        handler(newVal) {
          if (newVal) {
            this.editorData = JSON.parse(JSON.stringify(newVal));
            this.initialApplicationData = JSON.parse(JSON.stringify(newVal));
          }
        }
      }
    },
    methods: {
      ...mapActions('runners', ['getRunner', 'setRunners']),
      fetchRunnersData(runnerId){
        this.getRunner(runnerId);
      },
      deleteDialog(){
        this.isDeleteDialog = !this.isDeleteDialog;
      },
      cancelModal(){
        this.$router.push('/runners');
      },
      deleteRunner(){
        let uuid = this.getCurrentRunner.uuid;
        Applications.deleteRunner(uuid).then(()=> {
          this.setRunners(
            {
              page: this.currentPage,
              pageSize: this.pageSize,
              search: this.searchQuery
            }
          );
          this.isdeleteDialog = false;
          this.$router.push('/runners');
        })
      },
      handleDataChange() {
        // This method should be fired every time the JSON editor's content changes
        this.changesOnApp = this.calculateChanges(this.initialApplicationData, this.editorData);
        console.log("Detected Changes:", this.changesOnApp); // Debug: Check detected changes
      },
      calculateChanges(original, updated) {
        const changes = {};
        Object.keys(updated).forEach(key => {
          if (JSON.stringify(original[key]) !== JSON.stringify(updated[key])) {
            changes[key] = updated[key];
          }
        });
        return changes;
      },
      updateRunner(){
        console.log(this.editorData);
        Applications.updateRunner(this.runnerId, this.editorData)
        .then(response => {
          console.log('Update successful', response);
          this.setRunners(
            {
              page: this.currentPage,
              pageSize: this.pageSize,
              search: this.searchQuery
            });
        })
        .catch(error => {
          console.error('Update failed', error);
          this.$emit('updateError', error);
        });
      }
    }
  }
  </script>
  
  <style>
  .json-editor-container {
    width: 100%;
    height: calc(83vh - 100px);
  }
  .jsoneditor-vue {
    height: calc(83vh - 100px);
  }
  .scrollable-card {
  max-height: calc(100vh - 100px); /* Set a maximum height */
  overflow-y: auto;  /* Enable vertical scrolling */
}
  .v-sheet.v-card {
      border-radius: 0px;
  }
  </style>
  